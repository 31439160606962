@import './fonts/fonts.css';

#root {
  width: 100vw;
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#actions-menu .MuiPaper-rounded {
  border-radius: 3px;
  box-shadow: 0px 3px 5px 0px rgba(20, 30, 85, 0.2), 0px 1px 18px 0px rgba(20, 30, 85, 0.12),
    0px 6px 10px 0px rgba(51, 51, 51, 0.14);
}

.MuiAccordion-rounded {
  border-radius: 0 !important;
}
