@font-face {
  font-family: 'Verlag SSM for Julius Baer';
  src: url('./VerlagSSmforBaer-XLight.woff2') format('woff2'),
    url('./VerlagSSmforBaer-XLight.woff') format('woff'),
    url('./VerlagSSmforBaer-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag SSM for Julius Baer';
  src: url('./VerlagSSmforBaer-Light.woff2') format('woff2'),
    url('./VerlagSSmforBaer-Light.woff') format('woff'),
    url('./VerlagSSmforBaer-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag SSM for Julius Baer';
  src: url('./VerlagSSmforBaer-Regular.woff2') format('woff2'),
    url('./VerlagSSmforBaer-Regular.woff') format('woff'),
    url('./VerlagSSmforBaer-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag SSM for Julius Baer';
  src: url('./VerlagSSmforBaer-Black.woff2') format('woff2'),
    url('./VerlagSSmforBaer-Black.woff') format('woff'),
    url('./VerlagSSmforBaer-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag SSM for Julius Baer';
  src: url('./VerlagSSmforBaer-Bold.woff2') format('woff2'),
    url('./VerlagSSmforBaer-Bold.woff') format('woff'),
    url('./VerlagSSmforBaer-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
